import { render, staticRenderFns } from "./PowerStationDeviceJoin.vue?vue&type=template&id=f50454b6&scoped=true"
import script from "./PowerStationDeviceJoin.vue?vue&type=script&lang=js"
export * from "./PowerStationDeviceJoin.vue?vue&type=script&lang=js"
import style0 from "./PowerStationDeviceJoin.vue?vue&type=style&index=0&id=f50454b6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f50454b6",
  null
  
)

component.options.__file = "PowerStationDeviceJoin.vue"
export default component.exports