<template>
    <div>
        <el-dialog :title="labelType == 'add' ? '新增' : '编辑'" :visible.sync="dialogVisible" width="600px">
            <el-form class="form-class" ref="form" :model="form" :rules="rules" label-width="120px">
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-form-item label="电站名称" prop="powerStationCode">
                            <el-select v-model="form.powerStationCode" placeholder="请选择电站" clearable
                                style="width: 100%">
                                <el-option v-for="item in powerStationList" :key="item.id"
                                    :label="item.powerStationName" :value="item.powerStationCode">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="设备品牌" prop="deviceBrand">
                            <el-input v-model="form.deviceBrand" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="SIP用户名" prop="sipUsername">
                            <el-input v-model="form.sipUsername" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="SIP用户认证ID" prop="deviceSn">
                            <el-input v-model="form.deviceSn" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="密码" prop="password">
                            <el-input v-model="form.password" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="通道编码ID" prop="channelCodeId">
                            <el-input v-model="form.channelCodeId" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="dialogVisible = false">取消</el-button>
                <el-button size="small" type="primary" @click="toAdd">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            multipleSelection: [],
            powerStationList: [],
            form: {},
            row: {},
            labelType: '',
            rules: {}
        };
    },
    methods: {
        init(type, row) {
            this.dialogVisible = true
            this.row = row
            this.labelType = type
            this.form = {}
            this.getPowerStation()
            if (type == 'edit') {
                this.getDetail()
            }
        },
        // 获取电站列表
        async getPowerStation() {
            let res = await this.$http.post("powerStation/list", { currPage: 1, pageSize: 100 });
            if (res.data.code == 200) {
                this.powerStationList = res.data.data.data
            }
        },
        // 监控管理-获取编辑详情
        async getDetail() {
            let data = { id: this.row.id }
            let res = await this.$http.post("/videoInfo/detail", data)
            if (res.data.code == 200) {
                this.form = res.data.data
            }
        },
        // 新增
        async toAdd() {
            let res = await this.$http.post("videoInfo/save", this.form);
            if (res.data.code == 200) {
                this.$emit('PowerStationAdd')
                this.dialogVisible = false
            }
        },
    }
};
</script>
<style></style>