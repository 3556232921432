<template>
  <div class="body">
    <div class="cards">
      <div
        class="card_left"
        @mousedown.stop="startResize"
        ref="resizableDiv"
        :style="{ width: divWidth + 'px' }"
      >
        <div class="top">
          <el-input
            v-model="filterDept"
            placeholder="请输入关键词查找组织"
          ></el-input>
          <!-- <el-button @click="addTopLevelTreeData" class="create_btn">
            <i class="el-icon-plus"></i>
          </el-button> -->
        </div>
        <div class="categoryCompany">
          <el-tree
            node-key="powerStationCode"
            :expand-on-click-node="false"
            ref="tree"
            default-expand-all
            :filter-node-method="filterNode"
            :highlight-current="true"
            :data="treeData"
            :renderContent="renderContent"
            :props="defaultProps"
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
      </div>
      <div class="card_right">
        <div class="top">{{ activeObj.powerStationName }}</div>
        <div class="two" style="display: flex; margin-bottom: 10px;">
          <div
            v-for="(item, index) in tabList"
            :key="index"
            @click="changeTab(item.activeIndex)"
            :class="{
              'item tabsList': true,
              activeItem: activeIndex == item.activeIndex
            }"
          >
            {{ item.label }}
          </div>
        </div>

        <!-- 头部部分 -->
        <div v-if="activeIndex == 1">
          <div ref="search_d" style="margin-bottom: 10px">
            <el-row type="flex" class="row-bg" justify="end">
              <!-- <el-col :span="19">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                  <el-form-item>
                    <el-select v-model="formInline.powerStationCode" placeholder="请选择电站" clearable>
                      <el-option v-for="item in powerStationList" :key="item.id" :label="item.powerStationName"
                        :value="item.powerStationCode">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="search">查询</el-button>
                  </el-form-item>
                </el-form>
              </el-col> -->
              <el-col :span="24" style="display: flex; justify-content: end;">
                <el-button type="primary" @click="newBtn">新建</el-button>
                <el-button type="primary" @click="downloadExcelTemplate"
                  >下载模板</el-button
                >
                <el-button
                  type="primary"
                  style="margin-left: 10px"
                  @click="toInputBut"
                  >上传文件</el-button
                >
                <input
                  type="file"
                  id="default-btn"
                  ref="fileInput"
                  @change="importClick"
                  style="display: none"
                />
                <exportBtn :params="expParams" />
              </el-col>
            </el-row>
          </div>
          <!-- 表格部分 -->
          <div>
            <el-table
              border
              ref="table"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              height="calc(100vh - 240px)"
            >
              <el-table-column
                label="序号"
                type="index"
                width="50"
                align="center"
              ></el-table-column>
              <el-table-column
                label="电站名称"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.powerStationName
                }}</template>
              </el-table-column>
              <el-table-column
                label="设备名称"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.deviceName
                }}</template>
              </el-table-column>
              <el-table-column
                label="设备类型名称"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.deviceTypeName
                }}</template>
              </el-table-column>
              <el-table-column
                label="在线状态"
                align="center"
                show-overflow-tooltip
                prop="runingStatus"
              >
                <template slot-scope="scope">
                  <div
                    v-if="scope.row.runingStatus == 0"
                    style="display: flex; justify-content: center;"
                  >
                    <div
                      style="background-color: #409EFF; width: 10px; height: 10px; border-radius: 10px; margin-top: 7px; margin-right: 10px;"
                    ></div>
                    <div>待机</div>
                  </div>
                  <div
                    v-if="scope.row.runingStatus == 1"
                    style="display: flex; justify-content: center;"
                  >
                    <div
                      style="background-color: #67C23A; width: 10px; height: 10px; border-radius: 10px; margin-top: 7px; margin-right: 10px;"
                    ></div>
                    <div>运行</div>
                  </div>
                  <div
                    v-if="scope.row.runingStatus == 2"
                    style="display: flex; justify-content: center;"
                  >
                    <div
                      style="background-color: #909399; width: 10px; height: 10px; border-radius: 10px; margin-top: 7px; margin-right: 10px;"
                    ></div>
                    <div>断连</div>
                  </div>
                  <div
                    v-if="scope.row.runingStatus == 3"
                    style="display: flex; justify-content: center;"
                  >
                    <div
                      style="background-color: #F56C6C; width: 10px; height: 10px; border-radius: 10px; margin-top: 7px; margin-right: 10px;"
                    ></div>
                    <div>故障</div>
                  </div>
                  <!-- {{
                  
                  scope.row.runingStatus == 0 ? "待机" 蓝 :
                  scope.row.runingStatus == 1 ? "运行" : scope.row.runingStatus
                  == 2 ? "断连" 灰 : scope.row.runingStatus == 3 ? "故障" : ""
                  }} -->
                </template>
              </el-table-column>

              <el-table-column
                label="设备IMEI"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.deviceSnImei
                }}</template>
              </el-table-column>
              <el-table-column
                label="电站创建人名称"
                width="120"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.createName
                }}</template>
              </el-table-column>
              <el-table-column
                label="操作"
                width="120"
                align="center"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="edit(scope.row)" size="small"
                    >编辑</el-button
                  >
                  <el-button
                    v-if="ifShow.equipmentShow"
                    type="text"
                    @click="del(scope.row)"
                    style="color:red;"
                    size="small"
                    >删除</el-button
                  >
                  <el-button type="text" @click="SN(scope.row)" size="small"
                    >SN</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="block">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.currPage"
                :page-sizes="[20, 50, 100, 200]"
                :page-size="queryInfo.pageSize"
                class="pagination-style"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div v-if="activeIndex == 2">
          <div ref="search_d" style="margin-bottom: 10px">
            <el-row type="flex" class="row-bg" justify="space-around">
              <el-col :span="24" style="display: flex; justify-content: end;">
                <el-button type="primary" @click="addVideo">新建</el-button>
                <!-- <el-button type="primary" @click="downloadExcelTemplate">下载模板</el-button>
                <el-button type="primary" style="margin-left: 10px" @click="toInputBut">上传文件</el-button> -->
                <input
                  type="file"
                  id="default-btn"
                  ref="fileInput"
                  @change="importClick"
                  style="display: none"
                />
                <exportBtn :params="expParams" />
              </el-col>
            </el-row>
          </div>
          <!-- 表格部分 -->
          <div>
            <el-table
              border
              ref="table"
              :data="videoList"
              tooltip-effect="dark"
              style="width: 100%"
              height="calc(100vh - 240px)"
            >
              <el-table-column
                label="序号"
                type="index"
                width="50"
                align="center"
              ></el-table-column>
              <el-table-column
                label="电站名称"
                align="center"
                show-overflow-tooltip
                prop="powerStationName"
              >
              </el-table-column>
              <el-table-column
                label="SIP用户名"
                align="center"
                show-overflow-tooltip
                prop="sipUsername"
              >
              </el-table-column>
              <el-table-column
                label="在线状态"
                align="center"
                show-overflow-tooltip
                prop="onlinestatus"
              >
                <template slot-scope="scope">{{
                  scope.row.onlinestatus == 1
                    ? "在线"
                    : scope.row.onlinestatus == 2
                    ? "离线"
                    : ""
                }}</template>
              </el-table-column>
              <el-table-column
                label="设备品牌"
                align="center"
                show-overflow-tooltip
                prop="deviceBrand"
              >
              </el-table-column>
              <el-table-column
                label="设备类型"
                width="120"
                align="center"
                show-overflow-tooltip
                prop="deviceType"
              >
              </el-table-column>
              <el-table-column
                label="操作"
                width="120"
                align="center"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="videoEdit(scope.row)"
                    size="small"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    v-if="ifShow.videoShow"
                    @click="videoDel(scope.row.id)"
                    style="color:red;"
                    size="small"
                    >删除</el-button
                  >
                  <!-- <el-button type="text" @click="SN(scope.row)" size="small">SN</el-button> -->
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="block">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.currPage"
                :page-sizes="[20, 50, 100, 200]"
                :page-size="queryInfo.pageSize"
                class="pagination-style"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>

        <el-dialog
          :title="labelType == 'add' ? '新增' : '编辑'"
          :visible.sync="showDialog"
          width="600px"
        >
          <el-form
            class="form-class"
            ref="form"
            :model="form"
            :rules="rules"
            label-width="120px"
          >
            <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="电站名称：" prop="powerStationCode">
                  <el-select
                    v-model="form.powerStationCode"
                    placeholder="请选择电站"
                    clearable
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in powerStationList"
                      :key="item.id"
                      :label="item.powerStationName"
                      :value="item.powerStationCode"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="设备名称：" prop="deviceName">
                  <el-input
                    v-model="form.deviceName"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="设备SN：" prop="deviceSn">
                  <el-input
                    v-model="form.deviceSn"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="设备类型：" prop="deviceTypeCode">
                  <el-select
                    style="width: 100%;"
                    v-model="form.deviceTypeCode"
                    placeholder="请选择设备类型"
                    clearable
                  >
                    <el-option
                      v-for="item in deviceTypeList"
                      :key="item.id"
                      :label="item.deviceTypeName"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="设备IMEI：" prop="deviceSnImei">
                  <el-input
                    v-model="form.deviceSnImei"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="采集类型" prop="collectType">
                  <el-select
                    v-model="form.collectType"
                    placeholder="请选择采集类型"
                    clearable
                  >
                    <el-option label="网关" :value="0"> </el-option>
                    <el-option label="数据棒" :value="1"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="网关编码：" prop="gateWayCode">
                  <el-input
                    v-model="form.gateWayCode"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer">
            <el-button size="small" @click="showDialog = false">取消</el-button>
            <el-button size="small" type="primary" @click="handleSave"
              >确定</el-button
            >
          </span>
        </el-dialog>

        <el-dialog title="二维码" :visible.sync="showQrcode">
          <img :src="qrcodeImg" alt="" width="300" height="300" class="imgs" />
        </el-dialog>
        <add ref="addRef" @PowerStationAdd="getVideoList"></add>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import QRCode from "qrcode";
import exportBtn from "@/components/exportBtn.vue";
import add from "./dialog/videoAdd.vue";
export default {
  components: { exportBtn, add },
  data() {
    return {
      labelType: "add",
      rules: {
        powerStationCode: [{ required: true, message: "请选择电站名称" }],
        deviceName: [{ required: true, message: "设备名称不能为空" }],
        deviceSn: [{ required: true, message: "设备SN不能为空" }],
        deviceTypeCode: [{ required: true, message: "请选择设备类型" }],
        collectType: [{ required: true, message: "请选择采集类型" }]
      },
      tabList: [
        {
          label: "设备管理",
          activeIndex: 1,
          currentRowType: 1
        },
        {
          label: "监控管理",
          activeIndex: 2,
          currentRowType: 0
        }
      ],
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {}
      },
      form: {},
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 256, //表格高度
      headerHeight: 0,
      formInline: {},
      deviceTypeList: [], //设备类型
      getdeviceTypeName: {},
      powerStationList: [], //电站
      getpowerStationName: {},
      showQrcode: false, // 二维码
      qrcodeImg: "",
      expParams: {
        url: "",
        fileName: ""
      },
      divWidth: 200, // 初始宽度
      defaultProps: {
        children: "childrens",
        label: "companyName"
      },
      customTreeShowAll: true,
      filterDept: "",
      treeData: [],
      activeIndex: 1,
      activeObj: {},
      currentRowTree: {},
      videoList: [],
      ifShow: {}
    };
  },
  watch: {
    filterDept(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    // this.getList();
    this.getSelectList();
    this.getTreeData();
    let list = this.$store.state.menus;
    console.log(list, "listttttttttttttttt");
    list.forEach(item => {
      if (item.menuName == "基础设置") {
        if (
          item.menuName == "基础设置" &&
          item.childrens[0].childrens[1].menuName == "设备管理"
        ) {
          this.ifShow.equipmentShow = item.childrens[0].childrens[1].childrens[0].childrens.some(
            i => i.menuName == "设备管理删除"
          );
          this.ifShow.videoShow = item.childrens[0].childrens[1].childrens[0].childrens.some(
            i => i.menuName == "监控管理删除"
          );
        }
      }
    });
    console.log(this.ifShow, "ifShowwwwwwwwwwwwwww");
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },

  methods: {
    // 更改tab栏状态
    changeTab(index) {
      console.log(index);
      this.activeIndex = index;
      this.search();
      // switch (index) {
      //   case 1:
      //     this.search()
      //     break;
      //   case 2:
      //     this.getVideoList()
      //     break;
      //   default:
      // }
    },
    // 左边树的点击事件
    handleNodeClick(data, node) {
      console.log(data, "dataaaaaaaaaaaaaaaa");
      this.formInline.powerStationCode = data.powerStationCode;
      this.currentRowTree = data;
      // this.queryInfo.condition.companyCode = data.companyCode;
      this.activeObj.powerStationName = data.powerStationName;
      this.currentRowType = data.type;
      this.search();
      // this.handleActiveIndex();
    },

    // 查询
    search() {
      this.queryInfo.condition = this.formInline;
      this.getList();
      this.getVideoList();
    },

    // 监控管理--列表
    async getVideoList() {
      let res = await this.$http.post("/videoInfo/list", this.queryInfo);
      if (res.data.code == 200) {
        this.videoList = res.data.data.data;
      }
    },
    // 监控管理--新增
    addVideo() {
      this.$refs.addRef.init("add");
    },
    // 监控管理--编辑
    videoEdit(row) {
      this.$refs.addRef.init("edit", row);
    },
    /* 树形div伸缩--start */
    appendSearch() {},
    startResize(event) {
      this.startX = event.clientX;
      this.startWidth = this.$refs.resizableDiv.offsetWidth;
      document.addEventListener("mousemove", this.doResize);
      document.addEventListener("mouseup", this.stopResize);
    },
    doResize(event) {
      this.divWidth = this.startWidth + (event.clientX - this.startX);
      if (this.divWidth > 350) {
        this.customTreeShowAll = false;
      } else {
        this.customTreeShowAll = true;
      }
    },
    stopResize() {
      document.removeEventListener("mousemove", this.doResize);
      document.removeEventListener("mouseup", this.stopResize);
    },
    filterNode(value, data) {
      console.log(value, data, "lkkkkkkllllllll");
      if (!value) return true;
      return data.powerStationName.indexOf(value) !== -1;
    },
    /* 树形div伸缩--end */
    async getTreeData() {
      //获取左侧组织数据
      this.showRight = false;
      this.leftLoading = true;
      let res = await this.$http.post("/powerStation/powerStationList", {
        currPage: 1,
        pageSize: 100,
        condition: {
          userCode: JSON.parse(localStorage.getItem("userInfo")).userCode
        },
        groupCode: JSON.parse(localStorage.getItem("userInfo")).groupCode
      });
      this.showRight = true;
      this.leftLoading = false;
      if (res.data.code == 200) {
        console.log(res.data.data.data, "treeDataaaaaaaaaaaaaaaa");
        if (
          Array.isArray(res.data.data.data) &&
          res.data.data.data.length > 0
        ) {
          //数据校验
          this.treeData = res.data.data.data;
          console.log(this.currentRowTree, "kkkkkkkkkkkk");
          let obj = res.data.data.data[0];
          // let obj = this.currentRowTree
          //   ? this.currentRowTree
          //   : res.data.data.data[0]; // 当前是否有激活项
          console.log(obj, "objjjjjjjjjjjj");
          this.currentRowTree = obj;
          this.queryInfo.condition = obj;
          this.currentRowType = obj.type;
          this.formInline.powerStationCode = obj.powerStationCode;
          this.activeObj = obj;
          this.deptId = obj.id;
          this.search();
          setTimeout(() => {
            this.$refs.tree.setCurrentKey(obj.powerStationCode);
            this.deptContent = this.$refs.tree.getCurrentNode().duty;
            this.deptContentDialog = this.$refs.tree.getCurrentNode().duty;
          });
        }
      } else {
        this.$message.error(res.data.message);
      }
    },

    renderContent(h, { node, data, store }) {
      return (
        <span
          title={data.powerStationName}
          class={
            this.customTreeShowAll ? "custom-tree-node" : "custom-tree-node1"
          }
        >
          <span>
            <span>{data.powerStationName}</span>
          </span>
        </span>
      );
    },
    handleActiveIndex() {
      if (this.activeIndex != 1) {
        if (this.currentRowTree.type == 0 && this.activeIndex == 2) {
          let arr = this.authList.filter(item => item.activeIndex != 2);
          this.activeIndex = arr[0] ? arr[0].activeIndex : 0;
        }
        if (this.currentRowTree.type == 1 && this.activeIndex != 2) {
          this.activeIndex =
            this.authList.filter(item => item.currentRowType == 1)[0]
              ?.activeIndex || 0;
        }
      }
    },
    addTopLevelTreeData() {
      this.labelType = "add";
      this.dialog_CreateDept = true;
    },

    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 240;
    },

    SN(row) {
      this.$nextTick(() => {
        this.createqrCode(row.deviceSn);
        this.showQrcode = true;
      });
    },
    // 生成二维码
    createqrCode(text) {
      QRCode.toDataURL(text).then(url => {
        this.qrcodeImg = url;
      });
    },

    //获取数据
    async getList() {
      const res = await this.$http.post(
        "/powerStationDevice/list",
        this.queryInfo
      );
      if (res.data.code === 200) {
        this.total = res.data.data.count;
        this.tableData = res.data.data.data;
        console.log(this.tableData, "tableDataaaaaaaaaaa");
      }
    },

    // //获取数据
    // getList() {
    //   this.queryInfo.condition = this.formInline;
    //   var that = this;
    //   that.$http
    //     .post("/powerStationDevice/queryList", that.queryInfo)
    //     .then(function (response) {
    //       console.log(response.data.data);
    //       if (response.data.code == 200) {
    //         that.total = response.data.data.length;
    //         that.tableData = response.data.data;
    //       }
    //     });
    // },
    getSelectList() {
      // 电站列表
      this.$http
        .post("powerStation/list", { currPage: 1, pageSize: 100 })
        .then(res => {
          if (res.data.code == 200) {
            this.powerStationList = res.data.data.data;
            this.powerStationList.forEach(item => {
              this.getpowerStationName[item.powerStationCode] =
                item.powerStationName;
            });
          } else {
            this.$message.error(res.data.message);
          }
        });

      // 设备类型
      this.$http.post("deviceType/list", {}).then(res => {
        if (res.data.code == 200) {
          this.deviceTypeList = res.data.data.data;
          this.deviceTypeList.forEach(item => {
            this.getdeviceTypeName[item.code] = item.deviceTypeName;
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 保存
    handleSave() {
      var that = this;
      this.$refs["form"].validate(valid => {
        if (valid) {
          that.form.powerStationName =
            that.getpowerStationName[that.form.powerStationCode];
          that.form.deviceTypeName =
            that.getdeviceTypeName[that.form.deviceTypeCode];
          that.$http
            .post("/powerStationDevice/save", that.form)
            .then(function(response) {
              if (response.data.code == 200) {
                that.$notify.success({
                  title: "提示",
                  message: "保存成功",
                  showClose: true
                });
                that.showDialog = false;
                that.getList();
              } else {
                that.$notify.info({
                  title: "提示",
                  message: response.data.message,
                  showClose: true
                });
              }
            })
            .catch(function(error) {});
        }
      });
    },

    //修改
    edit(row) {
      this.showDialog = true;
      this.labelType = "edit";
      this.form = JSON.parse(JSON.stringify(row));
    },
    // 删除
    del(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$http.post("/powerStationDevice/delete", { id }).then(res => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
              this.getListData();
            } else {
              this.$message({
                type: "error",
                message: res.data.message
              });
            }
          });
        })
        .catch(() => {});
    },
    // 监控管理-删除
    videoDel(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$http.post("/videoInfo/delete", { id }).then(res => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
              this.getListData();
            } else {
              this.$message({
                type: "error",
                message: res.data.message
              });
            }
          });
        })
        .catch(() => {});
    },
    changeDeviceTypeCpde(val) {
      console.log(111);
      console.log(val, 429);
    },

    handleDelte(id) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          //  this.$message({
          //   type: "success",
          //   message: "删除成功!",
          // });
          const res = await this.$http.post("/powerStationDevice/delete", {
            id: id
          });
          if (res.data.code === 200) {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.getList();
          }
        })
        .catch(err => {
          console.log(err, 495);
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },

    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.form = {};
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
    toInputBut() {
      this.$refs.fileInput.click();
    },
    async importClick(event) {
      console.log("导入", event);
      const file = event.target.files[0];
      // 使用 FormData 包装文件
      const formData = new FormData();
      formData.append("file", file);
      formData.append("powerStationCode", this.formInline.powerStationCode);
      formData.append(
        "powerStationName",
        this.getpowerStationName[this.formInline.powerStationCode]
      );
      console.log("导入--", file);
      const res = await this.$http.post(
        "/powerStationDevice/import",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data" // 设置正确的Content-Type
          }
        }
      );
      if (res.data.code === 200) {
        this.getList();
        that.$notify.success({
          title: "提示",
          message: "导入成功",
          showClose: true
        });
      } else {
        this.$notify.info({
          title: "提示",
          message: res.data.message,
          showClose: true
        });
      }
    },
    // 下载模版
    async downloadExcelTemplate() {
      this.$message.success("开始下载...请稍等");
      const res = await axios({
        method: "get",
        url: "/powerStationDevice/export",
        responseType: "blob" // 设置响应类型为blob，用于处理文件流}, {}, {
        // headers: {
        //   "Content-Type": "multipart/form-data",
        //   Accept: "application/json", // 告诉服务器您希望接收JSON响应
        // },
      });

      const blob = new Blob([res.data]);
      // 创建一个临时的URL
      const url = window.URL.createObjectURL(blob);
      // 创建一个<a>标签用于下载
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // 设置下载的文件名
      a.download = "template.xlsx";
      document.body.appendChild(a);
      a.click();
      // 释放URL对象
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      // console.log(res, 'kkkkkkkkkkkk')
      // const url = "/powerStationDevice/export";
      // const xhr = new XMLHttpRequest();
      // xhr.open("GET", url, true);
      // xhr.responseType = "blob";
      // xhr.onload = () => {
      //   if (xhr.status === 200) {
      //     const blob = new Blob([xhr.response], {
      //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //     });
      //     const url = URL.createObjectURL(blob);
      //     const link = document.createElement("a");
      //     link.href = url;
      //     link.download = "deviceJoin.xlsx";
      //     link.click();
      //     URL.revokeObjectURL(url);
      //   }
      // };
      // xhr.send();
    },

    // // 下载模版
    // downloadExcelTemplate() {
    //   const url = "/excel/deviceJoin.xlsx";
    //   const xhr = new XMLHttpRequest();
    //   xhr.open("GET", url, true);
    //   xhr.responseType = "blob";
    //   xhr.onload = () => {
    //     if (xhr.status === 200) {
    //       const blob = new Blob([xhr.response], {
    //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //       });
    //       const url = URL.createObjectURL(blob);
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.download = "deviceJoin.xlsx";
    //       link.click();
    //       URL.revokeObjectURL(url);
    //     }
    //   };
    //   xhr.send();
    // },
    exportClick() {
      const url =
        "http://192.168.31.189:9001/api/powerStationDeviceJoin/export";
      axios({
        url: url,
        method: "POST",
        responseType: "blob" // 重要：设置响应类型为blob，以便处理二进制文件
      })
        .then(response => {
          console.log(response);
          // 创建一个新的URL代表指定的File对象或Blob对象
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
          );
          // 创建一个a标签用于下载
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "excel.csv"); // 下载文件名
          document.body.appendChild(link);
          link.click();

          // 清理
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch(error => {
          console.error("导出文件失败:", error);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.textblue {
  color: #008eff;
}

.managingPeople {
  // background: #2d5981;
  padding: 10px;

  .el-card {
    height: calc(100vh - 105px);
    padding: 8px;
    position: relative;

    /deep/.el-card__body {
      padding: 0;
    }

    .el-select > .el-input {
      width: 200px;
    }

    .header-search-box .header-search-input {
      width: 325px;
    }

    input::placeholder {
      font-size: 12px;
    }

    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}

.topCard {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .item {
    // width: calc((100vw - 60px) / 3);
    width: 200px;
    height: 90px;
    padding: 0 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .num {
      font-size: 22px;
      font-weight: 600;
      color: #d9001b;

      .unit {
        font-size: 14px;
      }
    }

    .title {
      color: #333;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      text-align: center;
      word-break: break-all;
    }
  }

  > .item:nth-child(1) {
    background-color: #f1fddd;
  }

  > .item:nth-child(2) {
    background-color: #fef1e1;
    margin-left: 20px;
  }

  > .item:nth-child(3) {
    background-color: #fadde0;
    margin-left: 20px;
  }
}

/deep/.el-input-group--prepend .el-input__inner,
/deep/ .el-input-group__append {
  border-color: #409eff;
  background-color: #409eff;
}

/deep/.el-input-group__append .el-button {
  // background-color: #409eff;
  // border-color: #409eff;
  color: white;
}

/deep/ .el-input-group__append .el-button--primary:hover {
  background: #66b1ff;
}

/deep/ .el-table {
  td {
    padding: 3px 0;
  }
}

// /deep/ .el-input__inner {
//   border-radius: 5px;
//   border: 1px solid rgba(151, 179, 203, 0.55);
//   background-color: rgba(255, 255, 255, 0);
//   box-sizing: border-box;
//   font-family: "Arial", sans-serif;
//   color: #f2f2f2;
//   text-align: left;
//   line-height: normal;
// }

/deep/ .el-table--border,
.el-table--group {
  border: 1px solid rgba(151, 179, 203, 0.55);
}

/deep/ .el-table--border th {
  background-color: #21527e;

  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

/deep/ .el-table th {
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

/deep/ .el-table td {
  background-color: #2d5981;
  border: 1px solid rgba(151, 179, 203, 0.55);
  box-sizing: border-box;
  font-family: "微软雅黑", sans-serif;
  color: #ffffff;
}

/deep/ .el-table__body-wrapper {
  background: #2d5981;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #223f6c !important;
}

/deep/ .el-table__body tr.current-row > td,
/deep/.el-table__body tr.hover-row > td {
  background-color: #223f6c;
}

/deep/ .el-pagination {
  display: flex;
  justify-content: flex-end;
}

/deep/ .el-pagination button:disabled {
  background: rgba(255, 255, 255, 0);
}

/deep/ .el-pager li.active {
  color: #f2f2f2;
  background-color: #00b2e6;
  cursor: default;
}

/deep/ .el-pager li {
  background: none;
  color: #f2f2f2;
}

/deep/ .el-icon {
  color: #fff;
}

/deep/ .el-pagination .btn-prev {
  background: none;
}

/deep/ .el-pagination .btn-next {
  background: none;
}

/deep/ .el-pagination__total {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 12px;
}

/deep/ .el-pagination__jump {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 15px;
}

/deep/ .noToolBar {
  padding: 0;

  .ql-editor {
    padding-top: 0;
  }

  .ql-toolbar {
    display: none;
  }

  .ql-container.ql-snow {
    border: none;
    padding-top: 0;
  }
}

/deep/ .el-card__body {
  padding: 0;
  height: 100%;
}

.main-index {
  height: 100%;
  padding: 10px;
  display: flex;

  /deep/.el-card {
    flex-grow: 1;
  }
}

.cards {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;

  .card_left {
    padding: 20px;
    padding-bottom: 0;
    background-color: #2d5981;
    min-width: 340px;
    flex-shrink: 0;
    cursor: e-resize;

    .top {
      color: #fff;
      display: flex;

      /deep/ .el-input__inner {
        height: 36px !important;
        line-height: 36px !important;
      }

      .el-button {
        height: 36px;
        border-radius: 7px;
      }

      ::v-deep .el-input__inner {
        background: #2d5981;
      }

      .create_btn {
        background: #02a7f0;
        margin-left: 12px;

        i {
          font-size: 18px;
          font-weight: 700;
          color: #fff;
        }
      }
    }

    .list {
      margin-top: 16px;
      margin-bottom: 0;
      height: calc(100vh - 150px);
      overflow: auto;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        cursor: pointer;
        height: 50px;
        list-style: none;
        background-color: #f2f2f2;
        color: #333;
        line-height: 50px;
        padding-right: 16px;
        padding-left: 40px;

        i {
          display: none;
        }

        > div {
          width: 220px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      li.active {
        background-color: #b2e4fb;
        font-weight: 600;
      }

      li:hover {
        background: darken(#f2f2f2, 15%);

        i {
          display: block;
        }

        i:hover {
          font-weight: 600;
        }
      }

      li.active:hover {
        background-color: #b2e4fb;
      }
    }
  }

  .card_right {
    margin-left: 16px;
    padding: 16px;
    background-color: #2d5981;
    flex-grow: 1;
    width: calc(100% - 356px);
    overflow-y: auto;

    /deep/.ql-editor.ql-blank::before {
      color: #d7d7d7;
    }

    // /deep/ .el-form-item__label{
    //   background-color: rgba(255, 255, 255, 0);
    //   box-sizing: border-box;
    //   font-family: "微软雅黑", sans-serif;
    //   color: #ffffff;
    //   text-align: left;
    //   line-height: normal;
    //   font-size: 21px;
    // }

    .el-tabs {
      color: #fcfcfc;

      /deep/.el-tabs__item.is-active {
        color: #409eff;
      }

      /deep/.el-tabs__item {
        color: #f2f2f2;
      }
    }

    .top {
      color: #fff;
      font-size: 20px;
      font-weight: 550;
      font-family: "微软雅黑 Bold", 微软雅黑, sans-serif;
    }

    .two {
      display: flex;
      width: 100%;
      margin-top: 12px;
      font-weight: 550;
      font-size: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid #d7d7d7;

      .item {
        position: relative;
        width: fit-content;
        color: #c1c1c1;
        cursor: pointer;
      }

      .activeItem {
        color: #02a7f0;
      }

      .item.activeItem::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        left: 0;
        bottom: -12px;
        background: #02a7f0;
      }
    }

    .postManage {
      margin-top: 10px;
      padding-left: 6px;

      /deep/.el-card {
        background-color: transparent;
      }

      /deep/.el-table {
        margin-top: 0;
      }
    }

    .table_box {
      margin-top: 10px;
      padding-left: 6px;
      width: 100%;

      .table_title {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .left {
          display: flex;

          .el-button {
            background: #02a7f0;
            color: #fff;
            border-radius: 4px;
            margin-left: 12px;
          }
        }

        .right {
          .new_btn {
            background: #02a7f0;
            color: #fff;
            border-radius: 4px;

            i {
              font-weight: 700;
            }
          }
        }
      }

      .table_main {
        width: 100%;
      }
    }

    .userManage {
      margin-top: 40px;
      padding-left: 6px;
      width: 100%;

      display: flex;

      .leftt {
        flex-shrink: 0;
        border: 1px solid #e3e3e3;
        width: 250px;
        height: calc(100vh - 258px);

        .topTitle {
          padding: 18px 10px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        ul.items {
          height: calc(100% - 90px);
          margin-top: 0;
          overflow: auto;

          li {
            cursor: pointer;
            background: #fff;
            list-style: none;
            padding: 10px 12px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              padding-left: 6px;
            }

            .hoverIcon {
              display: none;
            }
          }

          li:hover {
            background: #f1f1f1;

            .hoverIcon {
              display: block;
            }
          }

          li.activeItem {
            // background: #f2f2f2;
            background: darken(#f2f2f2, 5%);
          }
        }
      }

      > .right {
        box-sizing: border-box;
        // height: calc(100vh - 260px);
        width: calc(100% - 260px);
        padding: 18px;
        margin-left: 20px;
        border: 1px solid #e3e3e3;

        .nodeEvent {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

/deep/ .el-form-item__label::after {
  content: ":";
  padding-left: 2px;
}

/deep/ .online {
  color: #7f7f7f;
}

/deep/ .online::before {
  content: "";
}

/deep/ .samllPadding {
  padding: 0 4px;
}

/deep/ .categoryCompany {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #02a7f0;
  }

  margin-top: 16px;
  margin-bottom: 0;
  height: calc(100vh - 190px);
  overflow: auto;

  //    border: 1px dashed #02a7f0;
  .el-tree-node__content {
    height: 40px;

    .el-tree-node__label {
      font-size: 20px;
    }
  }

  .el-tree {
    color: #d7d7d7;
    background-color: transparent;
  }

  .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    // background-color: #698cab;
    background-color: #223f6c;
  }

  .el-tree-node__content:hover {
    background-color: transparent;
  }
}

/deep/ .custom-tree-node {
  font-size: 18px;

  i {
    display: none;
  }

  i:hover {
    color: #02a7f0;
  }
}

/deep/ .custom-tree-node1 {
  font-size: 18px;

  i {
    display: none;
    margin-left: 5px;
    margin-bottom: 5px;
  }

  i:hover {
    color: #02a7f0;
  }
}

/deep/ .el-tree-node__content:hover {
  i {
    display: inline-block;
  }
}

/deep/ .custom-tree-node {
  span {
    max-width: 230px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    word-break: break-all;
  }
}

/deep/ .custom-tree-node1 {
  span {
    margin-right: 10px;
    max-width: 1000px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    word-break: break-all;
  }
}

/deep/ .is-leaf {
  span {
    max-width: 230px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    word-break: break-all;
  }
}

// /deep/ .block-info {
//   margin-top: 10px;
//   position: relative;
//   margin-left: 12px;
// }
// .block-info::before {
//   content: "";
//   position: absolute;
//   left: -10px;
//   display: block;
//   width: 4px;
//   top: 2px;
//   height: 90%;
//   background: #02a7f0;
// }
.dept-box {
  height: calc(100vh - 240px);
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 8px;
  border: 1px solid #e6e9f3;
  color: #fff;

  .box-title {
    padding: 16px;
    position: relative;
    display: flex;
    justify-content: center;

    .right-btn {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    > span {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .box-inner {
    height: calc(100vh - 320px);
    border: 1px solid #e6e9f3;
  }

  .box-inner::-webkit-scrollbar {
    width: 0;
  }
}

/deep/ .ql-container {
  height: calc(100vh - 360px);
  overflow: auto;
}

/deep/ .ql-editor {
  height: 100%;
}

/deep/ .deptDialog {
  .el-dialog__header {
    text-align: left;

    span {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

/deep/ .button-img {
  height: 13px;
  margin-right: 5px;
}

/deep/ .el-upload--picture-card {
  height: 120px;
  width: 120px;
  line-height: 120px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  height: 120px;
  width: 120px;
}

/deep/ .el-upload-list__item {
  transition: none !important;
}

/deep/ .flex-start {
  justify-content: flex-start !important;
}

/deep/ .line-height44 {
  .item__label {
    font-size: 16px;
  }

  .el-form-item__content {
    line-height: 44px;
  }
}

/deep/ .tabsList + div {
  margin-left: 16px;
}

/deep/ .car-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .car-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

/deep/.card_left .el-input__inner {
  color: #e7e7e7;
}

.body {
  background: #223f6c;
  padding: 10px;
  height: 100%;

  .device-join {
    height: 100%;
    background: #2d5981;
    padding: 8px;

    #default-btn::file-selector-button {
      padding: 5.5px 10px;
      background-color: #409eff;
      border: 1px solid #409eff;
      border-radius: 3px;
      cursor: pointer;
      color: #fff;
      font-size: 12px;
    }

    .demo-form-inline {
      /deep/.el-form-item {
        margin-bottom: 0;

        /deep/ .el-form-item__label {
          color: #fff;
        }
      }
    }

    .form-class {
      /deep/ .el-form-item__label {
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
        font-size: 16px;
        padding-top: 5px;
        box-sizing: border-box;
      }

      /deep/ .el-input__inner {
        border-radius: 5px;
        border: 1px solid rgba(151, 179, 203, 0.55);
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
      }

      // /* 表单 */
      // /deep/.el-select {
      //   width: -webkit-fill-available;
      // }
    }

    /deep/ .el-input-group__append {
      background: #409eff;
      color: #fff;
    }

    /deep/ .el-form-item__label {
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
      font-size: 16px;
    }

    /deep/ .el-input__inner {
      border: 1px solid rgba(151, 179, 203, 0.55);
      background-color: rgba(255, 255, 255, 0);
      font-family: "Arial", sans-serif;
      color: #f2f2f2;
    }

    /deep/ .el-table--border,
    .el-table--group {
      border: 1px solid rgba(151, 179, 203, 0.55);
    }

    /deep/ .el-table--border th {
      background-color: #21527e;

      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    /deep/ .el-table th {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    /deep/ .el-table td {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    /deep/ .el-table__body-wrapper {
      background: #2d5981;
    }

    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: #223f6c !important;
    }

    /deep/ .el-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }

    /deep/ .el-pagination button:disabled {
      background: rgba(255, 255, 255, 0);
    }

    /deep/ .el-pager li.active {
      color: #f2f2f2;
      background-color: #00b2e6;
      cursor: default;
    }

    /deep/ .el-pager li {
      background: none;
      color: #f2f2f2;
    }

    /deep/ .el-icon {
      color: #fff;
    }

    /deep/ .el-pagination .btn-prev {
      background: none;
    }

    /deep/ .el-pagination .btn-next {
      background: none;
    }

    /deep/ .el-pagination__total {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 12px;
    }

    /deep/ .el-pagination__jump {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 15px;
    }
  }
}

/deep/ .el-icon-close:before {
  color: #333;
}

#default-btn::file-selector-button {
  padding: 5.5px 10px;
  background-color: #409eff;
  border: 1px solid #409eff;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
}

#default-btn {
  font-size: 0;
  margin: 0;
  padding: 0;
  // margin-left: 10px;
  // margin-right: 6px;
  // margin: 1px 6px 0 10px;
}

::v-deep .el-table--small .el-table__cell {
  padding: 0 !important;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}

::v-deep .el-form-item__label {
  text-align: end !important;
}

::v-deep .el-dialog__body {
  padding: 0 30px !important;
  text-align: center;
}

::v-deep .el-form-item--small.el-form-item {
  height: 36px !important;
}
</style>
